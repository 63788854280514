import * as routes from "../routes/routes";

export const LOGIN = `/${routes.ACCOUNT}/${routes.LOGIN}`;
export const ADMIN_CHANGE_PASSWORD = `/${routes.SETTINGS}/${routes.CHANGE_PASSWORD}`;

export const FORGOT_PASSWORD = `/${routes.ACCOUNT}/${routes.FORGOT_PASSWORD}`;

export const RESET_PASSWORD = `/${routes.ACCOUNT}/${routes.RESET_PASSWORD}`;

export const REPORTS = `/${routes.REPORTS}`;

export const USER = `/${routes.USER}`;

export const USER_LIST = `/${USER}/${routes.USER_LIST}`;

export const USER_DETAIL = `/${USER}/${routes.USER_DETAIL}`;

export const FEEDBACK = `/${routes.FEEDBACK}`;

export const REPORTS_LIST = `/${REPORTS}/${routes.LIST}`;

export const FEEDBACK_DETAIL = `/${FEEDBACK}/${routes.FEEDBACK_DETAIL}`;

export const SETTINGS = `/${routes.SETTINGS}`;

export const ADMIN_PROFILE = `/${SETTINGS}/${routes.ADMIN_PROFILE}`;

export const CHANGE_PASSWORD = `/${SETTINGS}/${routes.CHANGE_PASSWORD}`;

export const EDIT_PROFILE = `/${SETTINGS}/${routes.EDIT_PROFILE}`;

export const NOTIFICATION = `/${routes.NOTIFICATION}`;

export const NOTIFICATION_LIST = `/${NOTIFICATION}/${routes.NOTIFICATION_LIST}`;

export const ADD_NOTIFICATION = `/${NOTIFICATION}/${routes.ADD_NOTIFICATION}`;

export const EDIT_NOTIFICATION = `/${NOTIFICATION}/${routes.EDIT_NOTIFICATION}`;

export const CONTENT_MANAGEMENT = `/${routes.CONTENT_MANAGEMENT}`;
export const CONTENT_VIEW = `/${routes.CONTENT_MANAGEMENT}/${routes.VIEW}`;

export const DASHBOARD = `/${routes.DASHBOARD}`;
export const WRITING = `/${routes.WRITING}`;

export const INTEREST = `/${routes.INTEREST}`;
export const ADD_INTEREST = `/${routes.INTEREST}/${routes.ADD}`;

export const LANGUAGE = `/${routes.LANGUAGE}/${routes.LANGUAGE_LIST}`;

export const TEACHER = `/${routes.TEACHER}`;
export const ADD_TEACHER = `/${routes.TEACHER}/${routes.ADD}`;
export const TEACHER_DETAILS = `/${routes.TEACHER}/${routes.DETAILS}`;

export const EBOOK_LIST = `/${routes.EBOOK}`;
export const EBOOK_ADD = `/${routes.EBOOK}/${routes.ADD}`;
export const EBOOK_DETAILS = `/${routes.EBOOK}/${routes.DETAILS}`;

export const REVIEW_LIST = `/${routes.REVIEW}/${routes.LIST}`;
export const PAYMENT_LIST = `/${routes.PAYMENT}/${routes.LIST}`;

export const PUBLICATION_LIST = `/${routes.PUBLICATIONS}`;
export const PUBLICATIONS_DETAILS = `/${routes.PUBLICATIONS}/${routes.DETAILS}`;

export const SUB_ADMIN_LIST = `/${routes.SUB_ADMIN}`;
export const ADD_SUB_ADMIN = `/${routes.SUB_ADMIN}/${routes.ADD}`;
export const SUB_ADMIN_DETAIL = `/${routes.SUB_ADMIN}/${routes.DETAILS}`;
export const DISCUSSION = `/${routes.DISCUSSION}/${routes.LIST}`;
export const DISCUSSION_DETAILS = `/${routes.DISCUSSION}/${routes.DETAILS}`;
export const WRITING_DETAILS = `/${routes.WRITING}/${routes.DETAILS}`;
export const SESSION_LIST = `/${routes.SESSION}/${routes.LIST}`;
export const SESSION_DETAILS = `/${routes.SESSION}/${routes.DETAILS}`;
export const FAQ = `/${routes.FAQ}/${routes.LIST}`;
export const NEW_BOOKS = `/${routes.REPORTS}/${routes.NEW_BOOK}`;
export const TOP_BOOKS = `/${routes.REPORTS}/${routes.TOP_BOOKS}`;
export const NEW_USERS = `/${routes.REPORTS}/${routes.NEW_USERS}`;
export const NEW_SESSION = `/${routes.REPORTS}/${routes.NEW_SESSION}`;
export const NEW_TEACHER = `/${routes.REPORTS}/${routes.NEW_TEACHER}`;
export const CONTACT_US = `/${routes.CONTACT_US}/${routes.LIST}`;

export const CHANNELS = `/${routes.CHANNELS}/${routes.LIST}`;
export const CHANNEL_DETAILS = `/${routes.CHANNELS}/${routes.DETAILS}`;
export const ADMIN_NOTIFICATIONS = `/${routes.ADMIN_NOTIFICATIONS}`;
export const SUBSCRIPTIONS = `/${routes.SUBSCRIPTIONS}`;
export const SUBSCRIBERS = `/${routes.SUBSCRIBERS}`;

export const ANNOUNCEMENT = `/${routes.ANNOUNCEMENT}`;
export const REWARDS = `/${routes.REWARDS}`;
export const MARKETPLACE = `/${routes.MARKETPLACE}`;
export const ADD_MARKETPLACE = `/${routes.MARKETPLACE}/${routes.ADD}`;

export const SCHOOL_LIST = `/${routes.SCHOOL}/${routes.LIST}`;
export const SCHOOL_DETAIL = `/${routes.SCHOOL}/${routes.DETAILS}`;
export const ADD_SCHOOL = `/${routes.SCHOOL}/${routes.ADD}`;
export const EDIT_SCHOOL = `/${routes.SCHOOL}/${routes.EDIT}`;

export const EDUCATOR_LIST = `/${routes.EDUCATOR}/${routes.LIST}`;
export const EDUCATOR_DETAIL = `/${routes.EDUCATOR}/${routes.DETAILS}`;
export const ADD_EDUCATOR = `/${routes.EDUCATOR}/${routes.ADD}`;
export const EDIT_EDUCATOR = `/${routes.EDUCATOR}/${routes.EDIT}`;

export const STUDENT_LIST = `/${routes.STUDENT}/${routes.LIST}`;
export const STUDENT_DETAIL = `/${routes.STUDENT}/${routes.DETAILS}`;
export const ADD_STUDENT = `/${routes.STUDENT}/${routes.ADD}`;
export const EDIT_STUDENT = `/${routes.STUDENT}/${routes.EDIT}`;

export const GAME_LIST = `/${routes.GAME}/${routes.LIST}`;
export const GAME_DETAIL = `/${routes.GAME}/${routes.DETAILS}`;
export const ADD_GAME = `/${routes.GAME}/${routes.ADD}`;
export const EDIT_GAME = `/${routes.GAME}/${routes.EDIT}`;

export const GAME_CATEGORY_LIST = `/${routes.GAME_CATEGORY}/${routes.LIST}`;
export const ADD_GAME_CATEGORY = `/${routes.GAME_CATEGORY}/${routes.ADD}`;
export const EDIT_GAME_CATEGORY = `/${routes.GAME_CATEGORY}/${routes.EDIT}`;

export const VERSION_LIST = `/${routes.VERSION}/${routes.LIST}`;
export const ADD_VERSION = `/${routes.VERSION}/${routes.ADD}`;