// tslint:disable-next-line:no-namespace
export const LOGIN = "admin/v1/login";
export const FORGOT_PASSWORD = "admin/v1/forgot";
export const CHANGE_PASSWORD = "admin/v1/change-password";
export const ADMIN = "admin/v1/admins/";
export const RESET_PASSWORD = "admin/v1/resetPassword";
export const LOGOUT = "admin/v1/admins/logout";
export const VALIDATE_TOKEN = "admin/v1/admins/verify-token";
export const USER = "admin/v1/user";
export const FEEDBACK = "admin/v1/feedback";
export const NOTIFICATION = "admin/notification/v1/notifications";
export const SEND_NOTIFICATION = "admin/notification/v1/notifications";
export const DASHBOARD = "admin/v1/dashboard";

export const INTEREST_LIST = "admin/v1/interest";
export const CREATE_INTEREST = "admin/v1/interest/create";
export const UPDATE_INTEREST_STATUS = "admin/v1/interest/status";

export const CONTENT_GET = "admin/cms/v1/page";
export const UPDATE_CONTENT = "admin/cms/v1/page";

export const PARENT_LIST = "admin/v1/parent";
export const PARENT_ACTION = "admin/v1/parent/action";
export const CHILD_LIST = "admin/v1/child/list";

export const CHILDREN_LIST = "admin/v1/parent/children";

export const LANGUAGE_LIST_URL = "admin/v1/language";
export const LANGUAGE_STATUS_UPDATE = "admin/v1/language/status";

export const TEACHER = "admin/v1/teacher";
export const CREATE_TEACHER = "admin/v1/teacher/create";
export const TEACHER_ACTION = "admin/v1/teacher/action";
export const TEACHER_DETAILS = "admin/v1/teacher/details";

export const EBOOK = "admin/v1/ebook";
export const EBOOK_ACTION = "admin/v1/ebook/action";
export const EBOOK_DETAILS = "admin/v1/ebook/details";

export const REVIEW_URL = "admin/v1/ebook/reviews";
export const REVIEW_ACTION = "admin/v1/ebook/reviews/action";
export const CHILD_ACTION = "admin/v1/parent/child/action";

export const PUBLICATIONS = "admin/v1/ebook/publications";
export const PUBLISH_STATUS = "admin/v1/ebook/publish-status";

export const ADD_SUB_ADMIN_URL = "admin/v1/sub-admin";
export const SUB_ADMIN_LIST = "admin/v1/sub-admin";
export const SUB_ADMIN_ACTION = "admin/v1/sub-admin/action";
export const REPORT_URL = "admin/v1/report";
export const REPORT_DATA = "admin/v1/report/data";

export const DISCUSSION_LIST = "admin/forum/v1/list";
export const DISCUSSION_UPDATE = "forum/v1/edit";
export const DISCUSSION_ACTION = "admin/forum/v1/action";
export const UPDATE_DISCUSSION = "forum/v1/edit";
export const DISCUSSION_DETAILS = "forum/v1/detail";
export const DISCUSSION_ADD = "forum/v1/add";

export const WRITING_ADD = "admin/forum/v1/add-topic";
export const WRITING_UPDATE = "admin/forum/v1/edit-topic";

export const LIKE_LIST = "forum/v1/likes";
export const COMMENTS_LIST = "forum/v1/comment";
export const REPORT_LIST = "forum/v1/report/users";
export const SESSION_LIST = "admin/v1/session/list";
export const SESSION_DETAILS = "admin/v1/session/detail";
export const SESSION_ACTION = "admin/v1/session/action";
export const CONTENT_PAGE = "admin/cms/v1/detail/";
export const PUBLIC_FAQ = "admin/cms/v1/user/faq";
export const GET_FAQ = "admin/cms/v1/faq";
export const CONTACT_US = "admin/v1/contact/list";
export const DELETE_CONTACT_US = "admin/v1/contact/delete";
export const ADD_CONTACT_US = "admin/v1/contact/send";

export const CHANNEL_LIST = "admin/v1/channel";
export const CHANNEL_ADD = "admin/v1/channel";
export const UPDATE_CHANNEL = "admin/v1/channel";
export const CHANNEL_DETAILS = (id) => `admin/v1/channel/${id}`;
export const CHANNEL_ACTION = "admin/v1/channel/action";
export const CHANNEL_SUBADMINS = "admin/v1/sub-admins";
export const CHANNEL_CODE = "admin/v1/channel/code/generate";

export const CHANNEL_VIDEOS_LIST = "admin/v1/channel/video/list";
export const CHANNEL_VIDEOS = "admin/v1/channel/video";
export const CHANNEL_VIDEO_DETAILS = (id) => `admin/v1/channel/video/${id}`;
export const CHANNEL_VIDEO_ACTION = "admin/v1/channel/video/action";
export const VIDEO_COMMENTS_LIST = "admin/v1/channel/video/comments";
export const VIDEO_REPLIES_LIST = "admin/v1/channel/video/replies";
export const VIDEO_REPLY_ADD = "admin/v1/channel/video/comment";
export const VIDEO_REPLY_EDIT = "admin/v1/channel/comment/edit";
export const VIDEO_REPLY_DELETE = "admin/v1/channel/comments";

export const ADMIN_NOTIFICATIONS = "notification/v1/admin/list";
export const SUBSCRIPTION_LIST = "admin/v1/channel/bundle/list";
export const UPDATE_SUBSCRIPTION = "admin/v1/channel/bundle/package";
export const SUBSCRIPTION_ACTION = "admin/v1/channel/bundle/package";

export const SUBSCRIBER_LIST = "admin/v1/channel/subscriber";
export const ANNOUNCEMENT_API = "admin/v1/announcement";

export const REWARDS_API = "admin/v1/rewardCoins";
export const REWARDS_DROPDOWN_API = "admin/v1/rewardCoinsDropdown";

export const MARKETPLACE_API = "admin/v1/coupon";
export const MARKETPLACE_DETAIL_API =(id)=> `admin/v1/coupon/${id}`;
export const MARKETPLACE_REDEEM_COUPON_USER_API =(id)=> `admin/v1/coupon/redeemList/${id}`;

export const SCHOOL_API = "admin/v1/school";
export const DELETE_SCHOOL_API = "admin/v1/school/status";
export const SCHOOL_DETAIL_API =(id)=> `admin/v1/school/${id}`;

export const EDUCATOR_API = "admin/v1/educator";
export const REASSIGN_EDUCATOR_API = "admin/v1/educator/reassign";
export const EDUCATOR_STATUS_API = "admin/v1/educator/status";
export const EDUCATOR_DETAIL_API =(id)=> `admin/v1/educator/${id}`;

export const STUEDENT_API = "admin/v1/student";
export const STUEDENT_STATUS_API = "admin/v1/student/status";
export const STUEDENT_DETAIL_API =(id)=> `admin/v1/student/${id}`;

export const GAME_API = "admin/v1/category";
export const DELETE_QUESTION_API = "admin/v1/question";
export const GAME_STATUS_API = "admin/v1/category/status";
export const GAME_DETAIL_API =(id)=> `admin/v1/category/${id}`;

export const VERSION_API = "admin/v1/app-version";
export const DELETE_VERSION_API = "admin/v1/app-version/status";
